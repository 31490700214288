.scene-container {
    width: 100%;
    height: 100vh;
}

.scene-canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.scene-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    color: #fff;
}

.scene-message h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
}