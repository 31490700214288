.bg-button {
  width: 90px;
  height: 50px;
  background-size: cover;
  background-position: 0 50%;
  border: 1px solid #727272;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
  display: block;
  background-color: #777;
  transition: opacity;
}

.bg-button.selected {
  outline: 2px solid #177dfe;
}

.bg-selector {
  display: grid;
  padding: 13px 6px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 20px;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 1px solid #646464;
  max-height: 180px;
  overflow: scroll;
}

.upload-button {
  background-color: transparent;
  color: #727272;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  font-size: 13px;
  position: relative;
  padding-top: 12px;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border 0.2s ease-in-out;
}

.upload-button:hover,
.upload-button:focus {
  background-color: #177dfe !important;
  border: 1px solid #177dfe !important;
  color: #fff !important;
}

.upload-button:hover::before,
.upload-button:focus::before {
  background-image: var(--upload-icon-w);
}

.upload-button::before {
  content: "";
  top: 9px;
  left: 50%;
  margin-left: -7px;
  width: 14px;
  height: 12px;
  background-repeat: no-repeat;
  position: absolute;
  background-image: var(--upload-icon);
  transition: background-image 0.2s ease-in-out;
}
.bg-button.loading {
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-loading-indicator {
  font-family: "JetBrains Sans";
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -58%);
  color: rgb(114, 114, 114);
  font-size: 13px;
  white-space: nowrap;
}
