.template-controls {
  display: flex;
  flex-direction: row;
}

.template-annotation {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 7px;
}

.template-selector {
  margin-bottom: 40px;
  padding: 13px 6px;
  border: 1px solid #646464;
  background-color: #27282c;
  border-radius: 6px;
}

.template-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
}

.template-checkbox {
  display: none;
}

.template-option .checkbox {
  width: 30px;
  cursor: pointer;
}

.template-option .checkbox img {
  cursor: pointer;
}

.template-option .template-label {
  flex-grow: 5;
  color: #dfdfdf;
  font-size: 14px;
}

.checkbox.lock {
  opacity: 0.5;
  text-align: right;
  transition: opacity 150ms ease-in-out;
}

.checkbox.lock:hover {
  opacity: 0.8;
}

.checkbox.lock img {
  height: 25px;
}

.checkbox.eye {
  padding: 8px;
  margin-right: 5px;
  opacity: 0.5;
  transition: opacity 150ms ease-in-out;
}

.checkbox.eye:hover {
  opacity: 0.8;
}

.checkbox.eye img {
  width: 100%;
}
.checkbox.lock.locked {
  opacity: 1;
}

.checkbox {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

/* Tooltip styles */
.checkbox::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 8px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  pointer-events: none;
  white-space: nowrap;
}

/* Tooltip arrow */
.checkbox::after {
  content: "";
  position: absolute;
  bottom: 85%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

/* Show tooltip on hover */
.checkbox:hover::before,
.checkbox:hover::after {
  opacity: 1;
  visibility: visible;
}
