#Facebook.template {
  height: 630px;
  width: 1200px;
}

#Facebook .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 72px;
  height: 630px;
  width: 100%;
}

#Facebook.layout-5 .content {
  width: 50%;
  padding-right: 20px;
}

#Facebook.layout-6 .content {
  width: 70%;
  padding-right: 20px;
}

#Facebook.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#Facebook .title {
  color: #ffffff;
  font-weight: 600;
}

#Facebook .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  width: 100%;
}

#Facebook.layout-1 .title {
  font-size: 120px;
}

#Facebook.layout-2 .title {
  font-size: 96px;
}

#Facebook.layout-3 .title {
  font-size: 96px;
  margin-bottom: 24px;
}

#Facebook.layout-4 .title {
  font-size: 72px;
  margin-bottom: 24px;
}

#Facebook.layout-5 .title {
  font-size: 72px;
}

#Facebook.layout-6 .title {
  font-size: 72px;
}

#Facebook.layout-7 .title {
  font-size: 96px;
}

#Facebook header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#Facebook.layout-5 header,
#Facebook.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#Facebook .logo {
  margin-right: 20px;
  /*width: 335px;*/
}

#Facebook .logo.ide {
  /*width: 454px;*/
}

.EDU #Facebook .logo {
    /*width: 540px;*/
}

#Facebook .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 2px;
}

#Facebook .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#Facebook.layout-5 .product-name::before,
#Facebook.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 85px;
  position: relative;
}

#Facebook .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#Facebook .upload-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
  padding-top: 65px;
}

#Facebook.layout-5 .upload-wrap {
  width: calc(2 / 3 * 100%);
}

#Facebook.layout-6 .upload-wrap {
  width: 336px;
}

#Facebook .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}
/*Products fixes*/
.DN #Facebook .product-name::before {
  display: none;
}

.YT #Facebook .logo {
    /*width: 327px;*/
}

#Facebook.newsletter .title{
  font-size: 96px;
}

#Facebook.newsletter .date {
  border: 1px solid #fff;
  border-radius: var(--date-input-border-radius);
  color: #fff;
  display: inline-block;
  font-size: var(--date-input-font-size);
  font-weight: 400;
  margin-bottom: var(--date-input-margin-bottom);
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 6px;
  width: auto;
  z-index: 100;
  margin-top: 32px;
  position: relative;
}

#Facebook.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.JB #Facebook.newsletter::after {
  background-image: var(--jb);
  height: 394px;
  left: 691px;
  top: 166px;
  width: 489px;
  z-index: 3;
}

.JB #Facebook.newsletter .textarea {
  /*margin-top: -21px;*/
}

.IJ #Facebook.newsletter .product-name {
  display: none;
}

.IJ #Facebook.newsletter::after {
  background-image: var(--java);
  left: 757px;
  top: 60px;
  width: 383px;
  height: 485px;
  z-index: 3;
}

.DN #Facebook.newsletter .product-name {
  display: none;
}
.DN #Facebook.newsletter::after {
  background-image: var(--dn);
  left: 758px;
  top: 188px;
  width: 334px;
  height: 271px;
  z-index: 3;
}


/*MP*/

.MP #Facebook.newsletter .product-name {
  display: none;
}
.MP #Facebook.newsletter::after {
  background-image: var(--mp);
  width: 380px;
  height: 411px;
  left: 776px;
  top: 186px;
  z-index: 3;
}

/*PS*/

.PS #Facebook.newsletter .product-name {
  display: none;
}
.PS #Facebook.newsletter::after {
  background-image: var(--ps);
  width: 380px;
  height: 411px;
  left: 772px;
  top: 196px;
  z-index: 3;
}

/*TC*/

.TC #Facebook.newsletter .product-name {
  display: none;
}
.TC #Facebook.newsletter::after {
  background-image: var(--tc);
  width: 400px;
  height: 241px;
  left: 742px;
  top: 272px;
  z-index: 3;
}


