#YouTubeCommunityLivestream .product-name {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  min-width: 500px;
  margin-top: 16px;
  color: #ffffff;
  font-size: 48px;
}

#YouTubeCommunityLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#YouTubeCommunityLivestream.template {
  height: 1080px;
  width: 1080px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*background: linear-gradient(135deg, #0078d4, #fe4a49);*/
}

#YouTubeCommunityLivestream .content {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 1080px;
  width: 1080px;
  align-items: flex-start;
  padding: 72px 72px 72px 72px;
}

#YouTubeCommunityLivestream .badge {
  background-color: #000 !important;
  border-radius: 10px;
  color: #fff;
  content: "Livestream";
  display: block;
  font-size: 40px;
  font-weight: 600;
  height: 50px;
  line-height: 45px;
  margin-left: 33px;
  order: 3;
  padding: 0 30px 0 36px;
  z-index: 10;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  right: 49px;
  white-space: nowrap;
}

#YouTubeCommunityLivestream header {
  display: flex;
  /*align-items: center;*/
  width: 100%;
  font-size: 48px;
}

#YouTubeCommunityLivestream .logo {
  margin-right: 20px;
  display: flex;
  align-items: center;
  /*width: 335px;*/
}

#YouTubeCommunityLivestream .logo.ide {
  /*width: 377x;*/
}

#YouTubeCommunityLivestream .date {
  margin-bottom: 64px;
}

#YouTubeCommunityLivestream .name,
#YouTubeCommunityLivestream .date {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

#YouTubeCommunityLivestream .position {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 40px;
}

#YouTubeCommunityLivestream .title {
  font-weight: 600;
  color: #fff;
  font-size: 88px;
  line-height: 88px;
  height: 440px;
  width: 700px;
  margin-bottom: 140px;
}

#YouTubeCommunityLivestream .title-wrap {
  margin-top: 73px;
}

#YouTubeCommunityLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 810px;
}

#YouTubeCommunityLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}


.EDU #YouTubeCommunityLivestream .logo {
  /*width: 570px;*/
  margin-top: -2px;
}

.EDU #YouTubeCommunityLivestream .badge {
  margin-top: 10px;
  background-color: #000 !important;
  border-radius: 10px;
  color: #fff;
  content: "Livestream";
  display: block;
  font-size: 40px;
  font-weight: 600;
  height: 50px;
  line-height: 45px;
  margin-left: 33px;
  order: 3;
  padding: 0 30px 0 36px;
  z-index: 10;
  width: fit-content;
  justify-content: center;
  align-items: center;
}


.EDU #YouTubeCommunityLivestream .badge {
  margin-top: -4px;
  padding: 0 28px 0 28px;
  height: 52px;
  font-size: 38px;
  right: 233px;
  top: 85px;
}

.EDU #YouTubeCommunityLivestream .logo img{
  width: 460px;
  margin-top: -6px;
}

.EDU #YouTubeCommunityLivestream .title {
  font-size: 88px;
  line-height: 88px;
  width: 720px;
  margin-top: 60px;
  margin-bottom: 76px;
}

.EDU #YouTubeCommunityLivestream.template {
  background-color: white !important;
  background: unset;
}