:root {
  --zoom-level: 1;
}

.bg-transformer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bg-transformer .button-pack {
  flex-direction: column;
  display: none;
}

.bg-transformer .button-pack.visible {
  display: flex;
}

.bg-transformer .tools {
  position: absolute;
  top: 0;
  /*bottom: 3%;*/
  right: -95px;
  right: -50px;
  right: calc(-50px * var(--zoom-level, 1));
  display: grid;
  grid-template-rows: repeat(3, max-content) auto;
  /*grid-row-gap: 3%;*/
  align-items: flex-end;
  visibility: hidden;
}

/*.bg-transformer:hover .tools {*/
.template-wrap:hover .bg-transformer .tools {
  visibility: visible;
}

.bg-transformer .image-wrapper {
  width: 100% !important;
  height: 100% !important;
  cursor: grab;
  isolation: isolate;
  -webkit-isolation: isolate;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.bg-transformer .image-wrapper.active {
  pointer-events: auto;
}

.bg-transformer button.round-btn {
  zoom: 1;
  zoom: var(--zoom-level, 1);
  -webkit-transform: scale(1);
  -webkit-transform: scale(var(--zoom-level, 1));
  transform: scale(1);
  /*transform: scale(var(--zoom-level, 1));*/
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  cursor: pointer;
  color: #fff !important;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  font-family: "arial", sans-serif !important;
  opacity: 0.5;
  transition: opacity 150ms ease-in-out;
}

.bg-transformer button.round-btn:hover {
  opacity: 0.8;
}


.bg-transformer .round-btn.expander {
  opacity: 0.5;
}

.bg-transformer .round-btn.expander.opaque {
  opacity: 1;
}
