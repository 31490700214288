#Email04.template {
  height: calc(2 * 325px);
  width: calc(2 * 650px);
  display: flex;
  align-items: stretch;
  justify-content: center;
}

#Email04 .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 290px 96px 48px;
}

#Email04 .textarea {
  text-align: center;
}

#Email04 .title {
  color: #ffffff;
  font-weight: 600;
  font-size: 86px;
  /*line-height: 98px;*/
  line-height: 1;
}

#Email04 .upload-wrap {
  position: absolute;
  padding-top: 130px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  height: 100%;
}

#Email04.layout-3 .title-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

#Email04.layout-3 .badge {
  background: #fff;
  font-size: 36px;
  font-weight: 600;
  color: #000;
  padding: 24px 64px;
  border-radius: 48px;
}

#Email04.layout-3 .badge .textarea {
  line-height: 48px;
}

#Email04.layout-4 .title-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

#Email04.layout-4 .subtitle .textarea {
  font-size: 80px;
  line-height: 88px;
}
