#Voucher.template {
  height: 550px;
  width: 850px;
}

#Voucher .content {
  /*background-position: 0 -500px;*/
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  height: 550px;
  width: 100%;
}

#Voucher .title {
  font-size: 35px;
  font-weight: 400;
  padding-left: 60px;
  color: #fff;
}

#Voucher .subtitle {
  font-size: 35px;
  font-weight: 600;
  padding-left: 60px;
  color: #fff;
}

#Voucher .badge {
  font-size: 35px;
  font-weight: 400;
  height: 60px;
  background-color: #fff;
  color: #000;
  z-index: 11;
  border-radius: 10px;
  width: 720px;
  padding: 10px 35px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
}

#Voucher .title-wrap {
  margin-bottom: 102px;
}

#Voucher.layout-1 .title {
  font-size: 35px;
  margin-bottom: 6px;
}

#Voucher header {
  display: flex;
  font-size: 48px;
  align-items: center;
  margin-bottom: 47px;
}

#Voucher.layout-5 header,
#Voucher.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#Voucher .logo {
  margin-right: 20px;
  width: 394px;
}

#Voucher .logo img {
  width: 100%;
}

#Voucher .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 2px;
}

#Voucher .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#Voucher .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#Voucher .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
}

#Voucher.layout-5 .upload-wrap {
  width: calc(2 / 3 * 100%);
}

#Voucher.layout-6 .upload-wrap {
  width: 336px;
}

#Voucher .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

/*Products fixes*/
.DN #Voucher .product-name::before {
  display: none;
}

#Voucher.newsletter .title {
  font-size: 96px;
}

#Voucher.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

#Voucher .date {
  font-size: 20px;
  font-weight: 400;
  min-width: 425px;
  z-index: 11;
  margin-left: 62px;
  color: #fff;
  position: absolute;
  left: 35px;
  bottom: 42px;
}

#Voucher .link {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  z-index: 10;
  margin-left: 20px;
  position: absolute;
  left: 492px;
  bottom: 42px;
  text-decoration: underline;
}
