.font-button {
  height: 27px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 4px;
  display: block;
  background-color: transparent;
  color: #dfdfdf;
  font-size: 13px;
}

.font-button.selected {
  outline: 1px solid #177dfe;
}

.font-selector {
  display: grid;
  padding: 9px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 5px;
  border-radius: 6px;
  border: 1px solid #646464;
  background-color: #27282c;
}

.font-toggle input:checked + span {
  background-color: #177dfe !important;
}

.font-toggle input:checked + span::after {
  border-color: #177dfe !important;
}
