#Email03.template {
  height: calc(2 * 325px);
  width: calc(2 * 650px);

  display: flex;
  align-items: stretch;
  justify-content: center;
}

#Email03 .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 200px 96px 182px;
}

#Email03 .textarea {
  text-align: center;
}

#Email03 .title {
  color: #ffffff;
  font-weight: 600;
  font-size: 144px;
}

#Email03 .title .textarea {
  line-height: 144px;
}

#Email03 .upload-wrap {
  position: absolute;
  padding-top: 130px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  height: 100%;
}

/*Layout-3*/
#Email03.layout-3 .title {
  font-size: 86px;
}

#Email03.layout-3 .title .textarea {
  line-height: 96px;
  overflow: hidden;
}

#Email03.layout-3 .content {
  padding: 20px 96px 0;
  height: 650px;
}

#Email03.layout-3 .badge {
  background: #fff;
  font-size: 36px;
  font-weight: 600;
  color: #000;
  padding: 24px 64px;
  border-radius: 48px;
  width: max-content;
  margin: 50px auto 0 auto;
  z-index: 1;
  /*margin-top: auto;*/
  /*position: absolute;*/
  /*bottom: 85px;*/
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
}

#Email03.layout-3 .badge .textarea {
  line-height: 48px;
}

/*Layout-4*/
#Email03.layout-4 .title {
  font-size: unset;
}

#Email03.layout-4 .title .textarea {
  font-size: 86px;
  font-weight: 600;
  width: 1200px;
}

#Email03.layout-4 .content {
  padding: 0 96px;
  height: 650px;
}

#Email03.layout-4 .name {
  margin: 0 auto;
}
#Email03.layout-4 .name .textarea {
  font-size: 80px;
  font-weight: 400;
  line-height: 92px;
  width: 1200px;
  margin-bottom: -150px;
  height: 250px;
}
