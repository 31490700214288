#LandingPage.template {
  height: calc(2 * 720px);
  width: calc(2 * 1280px);
}

#LandingPage .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 200px 192px 280px;
  height: calc(2 * 720px);
  width: 100%;
}

#LandingPage.layout-5 .content {
  width: 50%;
  padding-right: 20px;
}

#LandingPage.layout-6 .content {
  width: 70%;
  padding-right: 20px;
}

#LandingPage.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#LandingPage .title {
  color: #ffffff;
  font-size: 144px;
  font-weight: 600;
}

#LandingPage.layout-3 .title,
#LandingPage.layout-4 .title {
  margin-bottom: 50px;
}

#LandingPage .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 80px;
  line-height: 92px;
  width: 100%;
}

#LandingPage header {
  display: flex;
  font-size: 96px;
  align-items: center;
}

#LandingPage.layout-5 header,
#LandingPage.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#LandingPage .logo {
  margin-right: 20px;
}

#LandingPage .logo img{
  height: 144px;
}

#LandingPage .product-name {
  font-weight: 600;
  min-width: 500px;
  font-size: 96px;
  margin-top: 12px;
}

#LandingPage .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#LandingPage.layout-5 .product-name::before,
#LandingPage.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 170px;
  position: relative;
}

#LandingPage .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#LandingPage .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  height: 100%;
}

#LandingPage.layout-5 .upload-wrap {
  width: 50%;
}

#LandingPage.layout-6 .upload-wrap {
  width: calc(1 / 3 * 100%);
}

#LandingPage .upload-wrap .image-upload {
  border-radius: 40px 0 0;
}
