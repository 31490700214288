#Twitter.template {
  height: 675px;
  width: 1200px;
}

#Twitter .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 72px;
  height: 675px;
  width: 100%;
}

#Twitter.layout-5 .content {
  width: 50%;
  padding-right: 20px;
}

#Twitter.layout-6 .content {
  width: 70%;
  padding-right: 20px;
}

#Twitter.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#Twitter .title {
  color: #ffffff;
  font-weight: 600;
}

#Twitter .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  width: 100%;
}

#Twitter.layout-1 .title {
  font-size: 120px;
}

#Twitter.layout-2 .title {
  font-size: 96px;
}

#Twitter.layout-3 .title {
  font-size: 96px;
  margin-bottom: 24px;
}

#Twitter.layout-4 .title {
  font-size: 72px;
  margin-bottom: 24px;
}

#Twitter.layout-5 .title {
  font-size: 72px;
}

#Twitter.layout-6 .title {
  font-size: 72px;
}

#Twitter.layout-7 .title {
  font-size: 96px;
}

#Twitter header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#Twitter.layout-5 header,
#Twitter.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#Twitter .logo {
  margin-right: 20px;
  /*width: 335px;*/
}

#Twitter .logo.ide {
  /*width: 454px;*/
  /*margin-top: 6px;*/
}

.EDU #Twitter .logo {
    /*width: 540px;*/
}

#Twitter .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 2px;
}

#Twitter .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#Twitter.layout-5 .product-name::before,
#Twitter.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 85px;
  position: relative;
}

#Twitter .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#Twitter .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
}

#Twitter.layout-5 .upload-wrap {
  width: calc(2 / 3 * 100%);
}

#Twitter.layout-6 .upload-wrap {
  width: 336px;
}

#Twitter .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

/*Products fixes*/
.DN #Twitter .product-name::before {
  display: none;
}

#Twitter.newsletter .title {
  font-size: 96px;
}

#Twitter.newsletter .date {
  border: 1px solid #fff;
  border-radius: var(--date-input-border-radius);
  color: #fff;
  display: inline-block;
  font-size: var(--date-input-font-size);
  font-weight: 400;
  margin-bottom: var(--date-input-margin-bottom);
  /*padding: var(--date-input-padding);*/
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 6px;
  width: auto;
  z-index: 100;
  margin-top: 32px;
  position: relative;
}

#Twitter.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.IJ #Twitter.newsletter::after {
  background-image: var(--java);
  left: 723px;
  top: 106px;
  width: 383px;
  height: 485px;
  z-index: 3;
}

.IJ #Twitter.newsletter .product-name {
  display: none;
}

.JB #Twitter.newsletter::after {
  background-image: var(--jb);
  height: 394px;
  left: 691px;
  top: 195px;
  width: 489px;
  z-index: 3;
}


.DN #Twitter.newsletter .product-name {
  display: none;
}
.DN #Twitter.newsletter::after {
  background-image: var(--dn);
  left: 758px;
  top: 239px;
  width: 334px;
  height: 271px;
  z-index: 3;
}

.JB #Twitter.newsletter .textarea {
  /*margin-top: -21px;*/
}

.YT #Twitter .logo {
    /*width: 327px;*/
}


/*JBIDEs*/

.IDEs .product-name {
  display: none;
}

/*MP*/

.MP #Twitter.newsletter .product-name {
  display: none;
}
.MP #Twitter.newsletter::after {
  background-image: var(--mp);
  width: 380px;
  height: 411px;
  left: 772px;
  top: 196px;
  z-index: 3;
}

/*PS*/

.PS #Twitter.newsletter .product-name {
  display: none;
}
.PS #Twitter.newsletter::after {
  background-image: var(--ps);
  left: 681px;
  top: 181px;
  width: 451px;
  height: 327px;
  z-index: 3;
}

/*ST*/

.ST .product-name {
  display: none;
}

/*TC*/

.TC #Twitter.newsletter .product-name {
  display: none;
}
.TC #Twitter.newsletter::after {
  background-image: var(--tc);
  width: 400px;
  height: 241px;
  left: 737px;
  top: 283px;
  z-index: 3;
}

/*IS*/

.IS .product-name::before  {
  display: none;
}

/*AI*/

.AI .product-name{
  font-size: 0 !important;
}

.AI .product-name::before {
  font-size: 48px;
  left: -2px;
  position: relative;
  /*top: 0px;*/
  font-weight: 600;
  content: "AI" !important;
}

.AI #BlogSocialShare .product-name::before {
  font-size: calc( 2 * 48px );
  left: 2px;
  position: relative;
  /*top: 0px;*/
  font-weight: 600;
  content: "AI" !important;
}