#YouTubeFirstSlideLivestream.template {
  height: 1080px;
  width: 1920px;
}

#YouTubeFirstSlideLivestream .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 72px;
  height: 1080px;
  width: 100%;
  position: relative;
}

#YouTubeFirstSlideLivestream .title {
  color: #ffffff;
  font-weight: 500;
  width: 1180px;
  font-size: 140px;
}

#YouTubeFirstSlideLivestream .name {
  color: #ffffff;
  width: 1180px;
  font-size: 48px;
  font-weight: 600;
}

#YouTubeFirstSlideLivestream .position {
  color: #ffffff;
  width: 1180px;
  font-size: 48px;
  font-weight: 400;
}

#YouTubeFirstSlideLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#YouTubeFirstSlideLivestream .logo {
  margin-right: 20px;
  /*width: 452px;*/
}

.EDU #YouTubeFirstSlideLivestream .logo {
  /*width: 570px;*/
  margin-top: -2px;
}



#YouTubeFirstSlideLivestream .logo.ide {
  /*width: 452px;*/
}

#YouTubeFirstSlideLivestream .product-name {
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: 48px;
}

#YouTubeFirstSlideLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#YouTubeFirstSlideLivestream .product-name::after {
  content: "|";
  position: relative;
  left: 10px;
  top: 0;
}

#YouTubeFirstSlideLivestream .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#YouTubeFirstSlideLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
}

#YouTubeFirstSlideLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

#YouTubeFirstSlideLivestream .badge {
  /*background-color: #000 !important;*/
  /*border-radius: 35px;*/
  color: #fff;
  content: "Livestream";
  /*display: block;*/
  font-size: 48px;
  font-weight: 600;
  /*height: 67px;*/
  /*line-height: 45px;*/
  top: -2px;
  position: relative;
  /*order: 3;*/
  padding-left: 20px;
  padding-top: 0;
  /*padding: 5px 36px 0 46px;*/
  /*position: absolute;*/
  /*right: 72px;*/
  z-index: 10;
  /*width: fit-content;*/
}

.EDU #YouTubeFirstSlideLivestream .logo {
  width: 570px;
  margin-top: -2px;
}
.EDU #YouTubeFirstSlideLivestream .badge {
  background-color: #000 !important;
  border-radius: 10px;
  color: #fff;
  content: "Livestream";
  display: block;
  font-size: 48px;
  height: 66px;
  top: 1px;
  line-height: 45px;
  margin-top: 0;
  margin-left: 36px;
  padding: 5px 26px 6px 38px;
  z-index: 10;
  width: fit-content;
  justify-content: center;
  align-items: center;
}
.EDU #YouTubeFirstSlideLivestream .title {
  width: 1241px;
}
