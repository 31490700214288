#EmailPhotoLivestream.template {
  height: calc(144px * 2);
  width: calc(650px * 2);
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}
#EmailPhotoLivestream .bg {
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
/*#EmailPhotoLivestream .bg::after {*/
/*  content: "";*/
/*  height: 4px;*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 0;*/
/*  right: 0;*/
/*  width: calc(650px * 2);*/
/*  background-color: #fff;*/
/*  !*z-index: 0 !important;*!*/
/*}*/
#EmailPhotoLivestream .content {
  background-position: 0 calc(-500px * 2);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  height: calc(144px * 2);
}
#EmailPhotoLivestream .title {
  color: #ffffff;
  font-weight: 600;
  font-size: calc(43px * 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#EmailPhotoLivestream .name {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(43px * 2);
  font-weight: 400;
  align-items: center;
}

#EmailPhotoLivestream .textarea {
  text-align: center;
}

#EmailPhotoLivestream .upload-wrap {
  /*position: absolute;*/
  border-radius: calc(20px * 2) 0 0;
  height: calc(144px * 2);
  width: 100%;
}

#EmailPhotoLivestream  .dropzone {
  width: 200px;
  height: 200px;
  left: 0;
  top: 70px;
}

#EmailPhotoLivestream .image-mask-container {
  position: absolute;
  left: 514px;
  top: -20px;
  width: 300px;
  height: 300px;
  mask-image: url(#upload-mask);
  -webkit-mask-image: url(#upload-mask);
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
}

#EmailPhotoLivestream .image-mask-container > * {
  width: 100%;
  height: 100%;
}