#BlogSocialShareLivestream.template {
  height: calc(720px * 2);
  width: calc(1280px * 2);
}
#BlogSocialShareLivestream .content {
  background-position: 0 calc(-500px * 2);
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: calc(60px * 2) calc(72px * 2) calc(90px * 2) calc(72px * 2);
  height: calc(720px * 2);
  width: 100%;
  position: relative;
}
#BlogSocialShareLivestream .title {
  color: #ffffff;
  width: calc(930px * 2);
  font-size: calc(80px * 2);
  margin-top: 120px;
  margin-bottom: 130px;
  font-style: normal;
  font-weight: 600;
  line-height: calc(96px * 2);
}

#BlogSocialShareLivestream .name {
  color: #ffffff;
  width: calc(630px * 2);
  font-size: calc(40px * 2);
  font-weight: 600;
}
#BlogSocialShareLivestream header {
  display: flex;
  font-size: calc(48px * 2);
  align-items: center;
}
#BlogSocialShareLivestream .logo {
  margin-right: calc(20px * 2);
  /*width: calc(335px * 2);*/
}
#BlogSocialShareLivestream .logo.ide {
  /*width: calc(378px * 2);*/
}
#BlogSocialShareLivestream .product-name {
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: calc(48px * 2);
}
#BlogSocialShareLivestream .product-name::before {
  content: "|";
  margin: calc(0px * 2) calc(14px * 2) calc(0px * 2) calc(0px * 2);
  position: relative;
}
#BlogSocialShareLivestream .icon-wrap {
  position: absolute;
  right: calc(71px * 2);
  bottom: calc(58px * 2);
  z-index: 1;
}
#BlogSocialShareLivestream .upload-wrap {
  position: absolute;
  padding-top: calc(65px * 2);
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: calc(20px * 2) 0 0;
  height: 100%;
}
#BlogSocialShareLivestream .upload-wrap .image-upload {
  border-radius: calc(20px * 2) 0 0;
}
#BlogSocialShareLivestream .badge {
  background-color: #000 !important;
  border-radius: calc(10px * 2);
  color: #fff;
  content: "Livestream";
  display: none;
  font-size: calc(40px * 2);
  font-weight: 600;
  height: calc(50px * 2);
  line-height: calc(45px * 2);
  margin-top: 0;
  margin-left: calc(33px * 2);
  order: 3;
  padding: 0 calc(30px * 2) 0 calc(36px * 2);
  z-index: 10;
  width: fit-content;
  justify-content: center;
  align-items: center;
}



.EDU #BlogSocialShareLivestream .badge {
  margin-top: 12px;
}

.EDU #BlogSocialShareLivestream .logo {
  /*width: calc(460px * 2);*/
}

.EDU #BlogSocialShareLivestream .content {
  padding-left: calc(70px * 2);
  padding-right: calc(70px * 2);
  padding-top: 134px;

}

.EDU #BlogSocialShareLivestream .badge {
  margin-top: -60px;
  right: 60px;
  font-size: 78px;
  display: block;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 49px;
  padding-right: 50px;
}

.EDU #BlogSocialShareLivestream .title {
  margin-top: 70px;
}

.EDU #BlogSocialShareLivestream .logo img{
  width: calc(463px * 2);
  height: calc(110px * 2);
  margin-top: -69px;
}