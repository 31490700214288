#Email01.template {
  height: calc(2 * 96px);
  width: calc(2 * 650px);

  display: flex;
  align-items: stretch;
  justify-content: center;
}

#Email01 .content {
  padding: 60px 96px;
}

#Email01.layout-2 .content {
  padding: 47px 96px;
  letter-spacing: -0.9px;
}

#Email01 .text-wrap {
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  font-size: 86px;
  /*line-height: 98px;*/
  line-height: 1;
}

#Email01 .product-name {
  z-index: 1;
  margin-right: 16px;
}

#Email01.layout-2 .product-name {
  margin-right: 20px;
  margin-left: 6px;
}

#Email01.layout-2 .text-group {
  display: flex;
  align-items: baseline;
}

#Email01.layout-2 .eap {
  font-weight: 400;
}
#Email01 .upload-wrap {
  position: absolute;
  padding-top: 130px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  height: 100%;
}

#Email01 .badge {
  font-weight: 300;
}

/*EDU*/

.EDU #Email01.layout-2 .text-wrap {
  font-size: 70px;
}

.EDU #Email01.layout-2 .content {
  padding: 57px 96px;
}

.EDU.bg-10 #Email02.layout-3 .badge {
  background-color: #6858f6;
}

.EDU.bg-10 #Email03.layout-3 .badge {
  background-color: #6858f6;
}

.EDU.bg-10 #Email02.layout-3 .badge input {
  color: #ffffff !important;
}

.EDU.bg-10 #Email03.layout-3 .badge input {
  color: #ffffff !important;
}
