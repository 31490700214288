.layout-button {
  width: 90px;
  height: 50px;
  background-size: cover;
  background-position: 0 50%;
  border: 1px solid #b5b5b5;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
  display: block;
  background-color: transparent;
}

.layout-button.selected {
  border: 1px solid #177dfe;
}

.layout-selector {
  display: grid;
  padding: 13px 6px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 20px;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 1px solid #646464;
  background-color: #27282c;
}
