#InstagramStories.template {
  height: 1920px;
  width: 1080px;
}

#InstagramStories .dropzone {
  width: 100%;
}

#InstagramStories .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding: 96px 96px 240px;
  height: 1920px;
  width: 1080px;
  position: relative;
}

#InstagramStories.layout-5 .content,
#InstagramStories.layout-6 .content,
#InstagramStories.layout-7 .content {
  flex-direction: column;
}

#InstagramStories .title {
  color: #ffffff;
  font-size: 120px;
  font-weight: 600;
}

#InstagramStories.layout-2 .title {
  font-size: 96px;
}

#InstagramStories.layout-3 .title {
  font-size: 120px;
  margin-bottom: 23px;
}

#InstagramStories.layout-4 .title {
  font-size: 120px;
  margin-bottom: 45px;
}

#InstagramStories .title-wrap {
  margin-bottom: 307px;
}

#InstagramStories.layout-2 .title-wrap {
  margin-bottom: 133px;
}

#InstagramStories .subtitle {
  color: #ffffff;
  font-weight: 400 !important;
  font-size: 48px;
  width: 100%;
}

#InstagramStories.layout-4 .subtitle {
  font-size: 60px;
}

#InstagramStories.layout-3 .subtitle {
  font-size: 60px;
  line-height: 96px;
}

#InstagramStories.layout-5 .title-wrap,
#InstagramStories.layout-6 .title-wrap,
#InstagramStories.layout-7 .title-wrap {
  margin-top: 220px;
  margin-bottom: 0;
}

#InstagramStories header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#InstagramStories.layout-5 header,
#InstagramStories.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#InstagramStories .logo {
  margin-right: 20px;
  /*width: 327px;*/
}

#InstagramStories .logo.ide {
  /*width: 454px;*/
}

.EDU #InstagramStories .logo {
    /*width: 556px;*/
}

#InstagramStories .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 2px;
}

#InstagramStories .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#InstagramStories.layout-5 .product-name::before,
#InstagramStories.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 65px;
  position: relative;
}

#InstagramStories .upload-wrap {
  position: absolute;
  right: 0;
  height: 1056px;
  width: 830px;
  bottom: 0;
  border-radius: 20px 0 0;
  z-index: 1;
  /*overflow: hidden;*/
}

#InstagramStories.layout-6 .upload-wrap {
  /*position: absolute;*/
}

#InstagramStories .icon-wrap {
  z-index: 1;
}

/*Products fixes*/
.DN #InstagramStories .product-name::before {
  display: none;
}

#InstagramStories.newsletter .title{
  font-size: 120px;
  margin-bottom: 40px;
}

#InstagramStories.newsletter .date {
  border: 1px solid #fff;
  border-radius: var(--date-input-border-radius);
  color: #fff;
  display: inline-block;
  font-size: var(--date-input-font-size);
  font-weight: 400;
  margin-bottom: var(--date-input-margin-bottom);
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 6px;
  width: auto;
  z-index: 100;
  /*margin-top: 40px;*/
  position: relative;
}

#InstagramStories.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 240px;
  right: 0;
  width: 60%;
  height: 40%;
}

.JB #InstagramStories.newsletter::after {
  background-image: var(--jb);
  height: 594px;
  right: 88px;
  bottom: 398px;
  width: 552px;
  z-index: 3;
}

#InstagramStories.newsletter .title-wrap {
  position: absolute;
  top: 330px;
}

.JB #InstagramStories.newsletter .textarea {
  /*margin-top: -21px;*/
}

.IJ #InstagramStories.newsletter::after {
  background-image: var(--java);
  top: 810px;
  left: 426px;
  width: 560px;
  height: 710px;
  z-index: 3;
}

.IJ #InstagramStories.newsletter .product-name,
.IJ #InstagramStories.newsletter .logo
{
  display: none;
}


.IJ #InstagramStories.newsletter .title-wrap {
  position: absolute;
  top: 214px;
}

.IJ #InstagramStories.newsletter .title {
  margin-bottom: 30px;
}

.DN #InstagramStories.newsletter .product-name,
.DN #InstagramStories.newsletter .logo
{
  display: none;
}
.DN #InstagramStories.newsletter::after {
  background-image: var(--dn);
  top: 924px;
  left: 405px;
  width: 588px;
  height: 476px;
  z-index: 3;
}


/*MP*/

.MP #InstagramStories.newsletter .product-name,
.MP #InstagramStories.newsletter .logo {
  display: none;
}
.MP #InstagramStories.newsletter::after {
  background-image: var(--mp);
  width: 580px;
  height: 680px;
  left: 468px;
  top: 861px;
  z-index: 3;
}

.MP #InstagramStories.newsletter .title-wrap {
  position: absolute;
  top: 396px;
}

.MP #InstagramStories.newsletter .title {
  margin-bottom: 45px;
}

.MP #InstagramStories.newsletter .date {
  font-size: 60px;
}

/*PS*/

.PS #InstagramStories.newsletter .product-name,
.PS #InstagramStories.newsletter .logo {
  display: none;
}
.PS #InstagramStories.newsletter::after {
  background-image: var(--ps);
  top: 945px;
  left: 233px;
  width: 769px;
  height: 551px;
  z-index: 3;
}


/*TC*/

.TC #InstagramStories.newsletter .product-name,
.TC #InstagramStories.newsletter .logo
{
  display: none;
}
.TC #InstagramStories.newsletter::after {
  background-image: var(--tc);
  width: 600px;
  height: 680px;
  left: 429px;
  top: 907px;
  z-index: 3;
}

/*IJ*/


.IJ #InstagramStories.layout-7 .template-header {
  margin-top: 160px;
}
.IJ #InstagramStories.layout-7 .title-wrap {
  margin-top: 30px;
}

.IJ #InstagramStories .icon-wrap {
  margin-top: 100px;
}
.IJ #InstagramStories .icon-wrap .icon{
  /*position: absolute;*/
  /*right: 98px;*/
  /*top: 100px;*/
  width: 250px;
  height: 250px;
  background-size: 200px auto;
}
