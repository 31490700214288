#InstagramStoriesLivestream.template {
  height: 1920px;
  width: 1080px;
}

#InstagramStoriesLivestream .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 96px;
  height: 1920px;
  width: 1080px;
  position: relative;
}

#InstagramStoriesLivestream .title {
  color: #ffffff;
  font-size: 120px;
  font-weight: 600;
}

#InstagramStoriesLivestream .title-wrap {
  /*margin-bottom: 316px;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#InstagramStoriesLivestream .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 48px;
  width: 100%;
}

#InstagramStoriesLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
  margin-top: 171px;
}

#InstagramStoriesLivestream .logo {
  margin-right: 18px;
  /*width: 335px;*/
}

#InstagramStoriesLivestream .logo.ide {
  /*width: 452px;*/
}

#InstagramStoriesLivestream .product-name {
  font-weight: 600;
  min-width: 500px;
}

#InstagramStoriesLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#InstagramStoriesLivestream .upload-wrap {
  position: absolute;
  right: 0;
  height: 1920px;
  bottom: 0;
  border-radius: 20px 0 0;
  z-index: 1;
  /*overflow: hidden;*/
  width: 1080px;
}

#InstagramStoriesLivestream .icon-wrap {
  z-index: 1;
}

#InstagramStoriesLivestream .badge {
  background-color: #000;
  border-radius: 10px;
  color: #fff;
  display: block;
  z-index: 1;
  font-size: 48px;
  font-weight: 600;
  height: 57px;
  line-height: 45px;
  padding: 0 30px 0 36px;
  white-space: nowrap;
  margin-left: 2px;
  margin-top: 119px;
  margin-bottom: 74px;
}

#InstagramStoriesLivestream .date {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-top: 76px;
  /*margin-bottom: 500px;*/
}


.EDU #InstagramStoriesLivestream .logo {
  /*width: 570px;*/
  margin-top: -2px;
}

.EDU #InstagramStoriesLivestream .badge {
  margin-top: 93px;
  padding-top: 7px;
  padding-bottom: 12px;
  padding-left: 37px;
  padding-right: 27px;
  height: unset;
}
.EDU #InstagramStoriesLivestream .badge .textarea-wrap {
  margin-top: -5px;
}
.EDU #InstagramStoriesLivestream .logo img{
  width: 568px;
  height: 110px;
  margin-top: -19px;
}

.EDU #InstagramStoriesLivestream .dropzone {
  position: absolute;
  right: 20px;
  bottom: 80px;
}