#EmailBannerLivestream.template {
  height: calc(325px * 2);
  width: calc(650px * 2);
  display: flex;
  flex-direction: column;
}
#EmailBannerLivestream .content {
  background-position: 0 calc(-500px * 2);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(48px * 2);
  width: 100%;
  position: relative;
  height: calc(325px * 2);
}
#EmailBannerLivestream .title {
  color: #ffffff;
  font-weight: 600;
  font-size: calc(43px * 2);
  line-height: calc(49px * 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
#EmailBannerLivestream .name {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(40px * 2);
  font-weight: 400;
  align-items: center;
}

#EmailBannerLivestream .textarea {
  text-align: center;
}
