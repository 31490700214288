#YouTubeThumbnailLivestream.template {
  height: 720px;
  width: 1280px;
}

#YouTubeThumbnailLivestream .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 48px 90px 48px;
  height: 720px;
  width: 100%;
  position: relative;
}

#YouTubeThumbnailLivestream .title {
  color: #ffffff;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 96px;
  margin-top: 70px;
}

#YouTubeThumbnailLivestream  .title-wrap {
 max-width: 900px;
}

#YouTubeThumbnailLivestream .name {
  color: #ffffff;
  width: 630px;
  font-size: 40px;
  font-weight: 600;
}

#YouTubeThumbnailLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#YouTubeThumbnailLivestream .logo {
  /*margin-right: 20px;*/
  width: 72px !important;
  position: absolute;
  right: 58px;
  top: 48px;
}

#YouTubeThumbnailLivestream .logo.ide {
  /*width: 378px;*/
}

#YouTubeThumbnailLivestream .product-name {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: 48px;
}

#YouTubeThumbnailLivestream .product-name::after {
  content: "|";
  margin: 0 14px 0 11px;
  position: relative;
}

#YouTubeThumbnailLivestream .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#YouTubeThumbnailLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
  width: 60%;
}

#YouTubeThumbnailLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

#YouTubeThumbnailLivestream .badge {
  color: #fff;
  content: "Livestream";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  top: -2px;
  position: relative;
  /*padding-left: 20px;*/
  padding-top: 0;
  z-index: 10;
}

/*.EDU #YouTubeThumbnailLivestream .logo {*/
/*  width: 570px;*/
/*  margin-top: -2px;*/
/*}*/

.EDU #YouTubeThumbnailLivestream .badge {
  margin-top: 12px;
  display: none;
}

.EDU #YouTubeThumbnailLivestream .logo {
  /*width: 570px;*/
  margin-top: -2px;
}

.EDU #YouTubeThumbnailLivestream .title {
  font-size: 96px;
  width: 720px;
  margin-top: 64px;
}

.EDU #YouTubeThumbnailLivestream .product-name {
  display: none;
}


.EDU #YouTubeThumbnailLivestream .logo {
  position: static;
  width: 570px !important;
  margin-top: -14px;
}