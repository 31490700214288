#FacebookLivestream.template {
  height: 630px;
  width: 1200px;
}

#FacebookLivestream .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px;
  height: 675px;
  width: 100%;
  position: relative;
}

#FacebookLivestream .title {
  color: #ffffff;
  width: 800px;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  margin-top: 45px;
  margin-bottom: 53px;
}

#FacebookLivestream .name {
  color: #ffffff;
  width: 630px;
  font-size: 40px;
  font-weight: 600;
}

#FacebookLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#FacebookLivestream .logo {
  margin-right: 20px;
  /*width: 335px;*/
}

#FacebookLivestream .logo.ide {
  /*width: 378px;*/
}

#FacebookLivestream .product-name {
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: 48px;
}

#FacebookLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#FacebookLivestream .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#FacebookLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
  width: 60%;
}

#FacebookLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

#FacebookLivestream .badge {
  background-color: #000 !important;
  border-radius: 10px;
  color: #fff;
  content: "Livestream";
  display: block;
  font-size: 40px;
  font-weight: 600;
  height: 50px;
  line-height: 45px;
  margin-top: 0;
  margin-left: 33px;
  order: 3;
  padding: 0 30px 0 36px;
  z-index: 10;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.EDU #FacebookLivestream .badge {
  margin-top: -4px;
  padding: 0 28px 0 28px;
  font-size: 38px;
}

.EDU #FacebookLivestream .logo img{
  width: 460px;
  margin-top: -8px;
}

.EDU #FacebookLivestream .title {
  font-size: 80px;
  line-height: 80px;
  width: 720px;
  margin-top: 42px;
  margin-bottom: 53px;
}